<template>
  <div class="card card-custom gutter-b">
    <div class="card-body pt-4">
      <div class="d-flex align-items-center">
        <div
          class="symbol symbol-60 symbol-xxl-120 mr-5 align-self-start align-self-xxl-center"
        >
          <router-link :to="`/sites/${this.$route.params.id}/details`">
            <span class="font-size-h1 symbol-label font-weight-boldest">{{
              initials(firstName)
            }}</span>
          </router-link>
        </div>
        <div>
          <router-link
            class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
            :to="`/sites/${this.$route.params.id}/details`"
            >{{ name }}
          </router-link>

          <a href="#"
            ><i class="flaticon2-correct text-success font-size-h5"></i
          ></a>
          <div class="text-muted">{{ account.website }}</div>
          <div class="mt-2">
            <b-button
              class="btn btn-sm btn-info font-weight-bolder text-uppercase"
              v-b-modal.account-modal
              >Editar</b-button
            >
            <modalCode
              v-if="account._id"
              :account="account"
              class="mr-5"
            ></modalCode>
          </div>
          <div v-if="this.check('reCrawler')" class="recrawler mt-2 d-flex">
            <button
              @click="recrawler()"
              class="btn btn-success font-weight-bold text-uppercase mr-2"
            >
              Reescanear
            </button>
          </div>
        </div>
      </div>
      <div class="pt-8 pb-6">
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">Criado em:</span>
          <a href="#" class="text-muted text-hover-primary">{{ createdAt }}</a>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <span class="font-weight-bold mr-2">Atualizado em:</span>
          <span class="text-muted">{{ updatedAt }}</span>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between">
          <span class="font-weight-bold mr-2">Location:</span>
          <span class="text-muted">Melbourne</span>
        </div> -->
      </div>
      <div class="pb-6">
        <StepsProgress v-if="account._id" :item="account" />
      </div>
    </div>

    <modalAccount :account="account"></modalAccount>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import moment from "moment";
import initials from "@/core/mixins/initials";
import ACL from "@/mixins/acl.js";
import Swal from "sweetalert2";
import modalAccount from "./modal/Index";
import modalCode from "./modal/code";
import StepsProgress from "@/view/content/widgets/steps-progress/index.vue";

export default {
  name: "SiteHeader",

  components: {
    modalAccount,
    modalCode,
    StepsProgress
  },

  props: {
    account: { type: Object, required: true }
  },

  mixins: [initials, ACL],

  methods: {
    recrawler() {
      ApiService.setHeader();
      ApiService.put(`/account/${this.account._id}/reCrawler`)
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Re-escanear executado com sucesso",
            showConfirmButton: false,
            timer: 1500
          });
        })
        .catch(() => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Re-escanear falhou, tente novamente.",
            showConfirmButton: false,
            timer: 1500
          });
        });
    }
  },

  computed: {
    ...mapGetters(["currentUserPhoto"]),

    name() {
      return this.account.name ? this.account.name.replace("https://", "") : "";
    },

    firstName() {
      return this.name.split(".")[0];
    },

    createdAt() {
      return moment(this.account.createdAt, "", "America/Sao_Paulo")
        .locale("pt-br")
        .format("DD/MM/YYYY, h:mm a");
    },

    updatedAt() {
      return moment(this.account.createdAt, "", "America/Sao_Paulo")
        .locale("pt-br")
        .format("DD/MM/YYYY, h:mm a");
    }
  }
};
</script>
