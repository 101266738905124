const initials = {
  methods: {
    initials(val) {
      return val
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    }
  }
};
export default initials;
