<template>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <Card :account="account" />
    </div>
    <div class="col-lg-8 col-md-12" v-if="account.stats">
      <CButton :account="account" />
    </div>
    <!-- <div class="col-lg-3">
      <Progress />
    </div> -->
  </div>
</template>

<script>
import Card from "./comps/card.vue";
import CButton from "./comps/c-button.vue";
// import Progress from "./comps/progress.vue";

export default {
  name: "Profile",

  props: {
    account: { type: Object, required: true }
  },

  components: {
    Card,
    CButton
    // Progress
  }
};
</script>

<style></style>
