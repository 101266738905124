const acl = {
  computed: {
    permissions() {
      let perms = [];
      if (this.$store.getters.currentUser?.plan?.configs) {
        let userPermissions = this.$store.getters.currentUser?.plan?.configs;
        Object.keys(userPermissions).forEach((key) => {
          if (userPermissions[key]) {
            perms.push(key);
          }
        });
      }
      return perms;
    },
  },
  methods: {
    check(perms) {
      return this.permissions.some(
        (permission) => permission.toLowerCase() === perms.toLowerCase()
      );
    },
  },
};

export default acl;
