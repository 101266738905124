<template>
  <div>
    <b-modal
      ref="account-modal"
      size="lg"
      centered
      hide-footer
      id="account-modal"
      :title="title"
    >
      <form class="form">
        <div class="card-body">
          <div class="form-group">
            <h5 class="font-weight-bold mb-6">Nome</h5>
            <input
              type="name"
              class="form-control form-control-solid"
              placeholder="Nome"
              v-model="account.name"
            />
            <!-- <span class="form-text text-muted">Nome do seu site</span> -->
          </div>
          <div class="form-group">
            <h5 class="font-weight-bold mb-6">Título ou Slogan</h5>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder="Título ou Slogan"
              v-model="account.title"
            />
            <!-- <span class="form-text text-muted"
            >Título do seu site ou Slogan</span
          > -->
          </div>
          <div class="form-group">
            <h5 class="font-weight-bold mb-6">Site</h5>
            <template v-if="!haveAccount">
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="https://site.com.br"
                v-model="account.website"
              />
            </template>
            <template v-else>
              <input
                type="text"
                class="form-control form-control-solid"
                placeholder="https://site.com.br"
                :value="account.website"
                disabled
                readonly="readonly"
              />
            </template>
            <!-- <span class="form-text text-muted"
            >URL do seu site.</span
          > -->
          </div>
          <!-- <div class="form-group">
            <h5 class="font-weight-bold mb-6">Plano</h5>
            <select
              type="text"
              class="form-control form-control-solid"
              placeholder="https://site.com.br"
              v-model="account.plan"
            >
              <option v-for="plan in plans" :key="plan._id" :value="plan._id" selected="selected">{{
                plan.name
              }}</option>
            </select>

   
          </div> -->
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary mr-2" @click="handleOk">
            Salvar
          </button>
          <button type="button" class="btn btn-secondary" @click="hideModal">
            Cancelar
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
export default {
  name: "account-modal",
  props: ["account"],
  data() {
    return {
      toSave: {},
      plans: []
    };
  },
  mounted() {
    this.getPlans();
    if (this.haveAccount) this.$set(this, "toSave", { ...this.account });
  },
  computed: {
    haveAccount() {
      return this.account._id;
    },
    title() {
      return this.haveAccount
        ? `Editando o site: ${this.account.website}`
        : "Criando um novo site";
    }
  },
  methods: {
    getPlans() {
      ApiService.post(`plans/all`, { query: { default: true } })
        .then(plans => {
          this.plans = plans.data.docs;
        })
        .catch(response => {
          console.log("DATA ERROR", response);
        });
    },
    showModal() {
      this.$refs["account-modal"].show();
    },
    hideModal() {
      this.$refs["account-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["account-modal"].toggle("#toggle-btn");
    },
    handleOk(event) {
      event.preventDefault();
      this.account._id ? this.update() : this.create();
    },
    update() {
      ApiService.put(`account/${this.account._id}`, this.account)
        .then(() => {
          this.$bvModal.hide("account-modal");
          Swal.fire({
            title: "Deu Certo!",
            text: "Seus dados foram atualizados com sucesso!",
            icon: "success",
            heightAuto: false
          });
        })
        .catch(response => {
          Swal.fire({
            title: "Ops!",
            text:
              "Tivemos algum problema, caso o mesmo persista,por favor informar nosso deparmento técnico.",
            icon: "error",
            heightAuto: false
          });
          console.log("ERROR", response);
        });
    },
    create() {
      ApiService.post(`account`, { ...this.account, plan: this.plans[0]._id })
        .then(data => {
          this.$bvModal.hide("account-modal");
          Swal.fire({
            title: "Deu Certo!",
            text: "Site cadastrado com sucesso!",
            icon: "success",
            heightAuto: false
          }).then(() => {
            this.$router.push(`/sites/${data.data._id}/details`);
          });
        })
        .catch(response => {
          Swal.fire({
            title: "Ops!",
            text:
              "Tivemos algum problema, caso o mesmo persista,por favor informar nosso deparmento técnico.",
            icon: "error",
            heightAuto: false
          });
          console.log("ERROR", response);
        });
    }
  }
};
</script>

<style></style>
