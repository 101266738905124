<template>
  <div class="btn-modal-code">
    <a
      @click="showModal"
      class="btn btn-icon btn-light btn-hover-primary btn-sm"
      content="<p><h6>Instalação da tag</h6> Veja como instalar nossa tag no seu site.</p>"
      v-tippy
    >
      <span class="svg-icon svg-icon-md svg-icon-primary">
        <!-- <inline-svg src="media/svg/icons/Code/Code.svg" /> -->
        <i class="icon fas fa-code"></i>
      </span>
    </a>
    <b-modal
      :ref="modalName"
      size="lg"
      centered
      hide-header
      ok-only
      ok-title="Fechar"
      :id="modalName"
      :title="title"
    >
      <!--begin::Heading-->
      <h2 class="text-dark mb-8 text-center">INSTALAÇÃO MANUAL</h2>
      <!--end::Heading-->
      <!--begin::Content-->
      <h4 class="font-weight-bold text-dark mb-4">
        Primeiro passo
      </h4>
      <span>Copie a nossa tag para inserir no seu site.</span>
      <div class="text-dark-50 line-height-lg mb-8">
        <div class="example-highlight">
          <input type="hidden" id="codeJS" :value="jsCode" />
          <highlight-code lang="html" class="language-json">
            {{ jsCode }}
          </highlight-code>
          <span
            class="btn btn-info text-white copy-btn ml-auto"
            @click.stop.prevent="copyCode"
          >
            Copiar
          </span>
        </div>
      </div>
      <h4 class="font-weight-bold text-dark mb-4">
        Segundo passo
      </h4>
      <p>Adicione a nossa tag entre a tag {{ bodyTag }} do seu site.</p>
      <div class="text-dark-50 line-height-lg mb-8">
        <div class="example-code">
          <div class="example-code">
            <span
              class="example-copy"
              v-b-tooltip.hover.top="'Copy code'"
            ></span>
            <div class="example-highlight">
              <highlight-code lang="html" class="language-json">
                {{ bodyCode }}
              </highlight-code>
            </div>
          </div>
        </div>
      </div>
      <!-- <template v-if="!account.steps.js">
        <div v-if="!verified">
          <h4 class="font-weight-bold text-dark mb-4">
            Terceiro passo
          </h4>
          <div class="text-dark-50 line-height-lg mb-8">
            <button
              class="btn btn-info text-white copy-btn ml-auto"
              :class="{ 'spinner spinner-white spinner-right': isVerify }"
              @click="verify"
            >
              Verificar Instalação
            </button>
            <div
              class="alert alert-custom alert-notice alert-light-danger fade show mt-10"
              role="alert"
            >
              <div class="alert-icon"><i class="flaticon-warning"></i></div>
              <div class="alert-text" v-html="error"></div>
            </div>
          </div>
        </div>
        <div v-else>
          <h1 class="text-success">
            Tag instalada com sucesso!
            <span class="far fa-laugh-wink"></span>
          </h1>
        </div>
      </template>
      <template v-else>
        <h1 class="text-success">
          Tag instalada com sucesso!
          <span class="far fa-laugh-wink"></span>
        </h1>
      </template> -->
      <!--end::Content-->
      <h2 class="text-dark mb-8 text-center">INSTALAÇÃO POR INTEGRAÇÃO</h2>
      <h4 class="font-weight-bold text-dark mb-4">
        Chave
      </h4>
      <p>
        Aplique essa <b>chave</b> no lugar que a nossa
        <router-link to="integrations" target="_blank"
          >documentação</router-link
        >
        informar.
      </p>
      <div class="text-dark-50 line-height-lg mb-8">
        <input type="hidden" id="codeHash" :value="hash" />
        <highlight-code lang="html" class="language-json">
          {{ hash }}
        </highlight-code>

        <span
          class="btn btn-info text-white copy-btn ml-auto"
          @click.stop.prevent="copyCodeHash"
        >
          Copiar
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import JwtService from "@/core/services/jwt.service";
import axios from "axios";
import ApiService from "@/core/services/api.service";
export default {
  name: "account-code",
  props: ["account"],
  data() {
    return {
      error: "",
      isVerify: false,
      verified: false,
      bodyTag: "<body></body>"
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["layoutConfig"]),
    config() {
      return this.layoutConfig();
    },
    hash() {
      return JwtService.generateToken({
        account: this.account._id
      });
    },
    jsCode() {
      return (
        "<" +
        "script" +
        ' src="https://consensu.io/bundle.min.js" key="' +
        this.hash +
        '" type="text/javascript" charset="utf-8"></' +
        "script" +
        ">"
      );
    },
    bodyCode() {
      return `
      <html>
      <head></head>
      <body>
      <!-- Seu Site --->
      <!-- Consensu.io Tag --->
      ${this.jsCode}
      </body>
      </html>
      `;
    },
    modalName() {
      return `account-code-${this.account._id}`;
    },
    haveAccount() {
      return this.account._id;
    },
    title() {
      return `Instalação do site: ${this.account.name}`;
    }
  },
  methods: {
    setStep() {
      if (this.verified) {
        this.account.steps.js = true;
        ApiService.put(`account/${this.account._id}`, this.account).then(
          result => {
            console.log(result);
          }
        );
      }
    },
    verify() {
      this.isVerify = true;
      this.error = "";
      axios
        .get(this.account.website)
        .then(result => {
          console.log("HTML", result.data);
          let js = result.data.search("consensu.io/bundle.min.js");
          let hash = result.data.search(this.hash);
          this.isVerify = false;
          this.verified = js >= 0 && hash >= 0;
          this.setStep();
        })
        .catch(() => {
          this.error = `Não foi possivel acessar o site: <b>${this.account.website}</b> para verificar a instalação.`;
          this.isVerify = false;
          this.verified = false;
        });
    },
    copyCodeHash() {
      let codeJS = document.querySelector("#codeHash");
      codeJS.setAttribute("type", "text"); // 不是 hidden 才能複製
      codeJS.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? "Tag copiada com sucesso!"
          : "Tag copiada com sucesso!";
        Swal.fire({
          text: msg,
          position: "top-end",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        // alert('Oops, unable to copy');
      }

      /* unselect the range */
      codeJS.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    copyCode() {
      let codeJS = document.querySelector("#codeJS");
      codeJS.setAttribute("type", "text"); // 不是 hidden 才能複製
      codeJS.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? "Tag copiada com sucesso!"
          : "Tag copiada com sucesso!";
        Swal.fire({
          text: msg,
          position: "top-end",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false
        });
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        // alert('Oops, unable to copy');
      }

      /* unselect the range */
      codeJS.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    showModal() {
      if (!this.account.steps.js) {
        this.verify();
      }

      this.$refs[this.modalName].show();
    },
    hideModal() {
      this.$refs[this.modalName].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs[this.modalName].toggle("#toggle-btn");
    },
    handleOk(event) {
      event.preventDefault();
      this.account._id ? this.update() : this.create();
    }
  }
};
</script>

<style scoped>
.btn-modal-code {
  width: 35px;
  float: left;
  right: 0;
}
</style>
