<template>
  <div>
    <div class="row">
      <template v-for="(button, index) in buttons">
        <div class="col-xl-6 col-md-6 col-sm-6" :key="index">
          <!--begin::Stats Widget 14-->
          <router-link
            :to="`/sites/${routePath}/${button.link}`"
            class="card card-custom  card-stretch gutter-b"
            :class="button.class"
          >
            <!--begin::Body-->
            <div
              class="card-body d-flex justify-content-between align-items-center"
            >
              <div>
                <p
                  class="text-inverse-primary font-weight-bolder font-size-h5 mb-2 mt-5"
                >
                  {{ button.label }}
                </p>
                <p
                  class="font-weight-boldest text-inverse-primary font-size-h1"
                >
                  {{ button.value }}
                </p>
              </div>

              <router-link :to="`/sites/${routePath}/${button.link}`">
                <i
                  :class="button.icon"
                  class="display-2 text-light font-weight-bold"
                >
                </i>
              </router-link>
            </div>
            <!--end::Body-->
          </router-link>
          <!--end::Stats Widget 14-->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "CButton",

  props: {
    account: { type: Object, required: true }
  },

  computed: {
    buttons() {
      return [
        {
          link: "details",
          label: "Visualizações",
          value: this.account.stats.views,
          icon: "flaticon-visible",
          class: "bg-success bg-hover-state-success"
        },
        {
          link: "consents",
          label: "Consentimentos",
          value: this.account.stats.consents,
          icon: "flaticon-globe",
          class: "bg-info bg-hover-state-info"
        },
        {
          link: "cookies",
          label: "Cookies",
          value: this.account.stats.cookies,
          icon: "flaticon-globe",
          class: "bg-warning bg-hover-state-warning"
        },
        {
          link: "banner",
          label: "Banner",
          value: this.account.consent.configs.type,
          icon: "flaticon-speech-bubble",
          class: "bg-primary bg-hover-state-primary"
        }
      ];
    },

    routePath() {
      return this.$route.params.id;
    }
  }
};
</script>

<style>
.card.card-custom > .card-body {
  padding: 1.8rem 1.25rem;
}
</style>
