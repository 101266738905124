<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <profile :account="account"></profile>
      </div>
    </div>

    <transition name="fade-in-up">
      <router-view :account="account" />
    </transition>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Profile from "@/view/content/widgets/profile/index.vue";

export default {
  name: "SiteId",

  data() {
    return {
      account: {},
      selected: ""
    };
  },

  components: {
    Profile
  },

  mounted() {
    this.getDetails();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile Detail" }]);
  },

  methods: {
    getDetails() {
      ApiService.query(`account/${this.$route.params.id}/details`)
        .then(({ data }) => {
          //console.log("DATA", data);
          this.account = data[0];
        })
        .catch(() => {
          //console.log("DATA ERROR", response);
        });
    }
  }
};
</script>

<style></style>
